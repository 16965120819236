import React from 'react';
import cx from 'classnames';
import s from './ProductPageApp.scss';
import {ClassicLayout} from '../Layouts/ClassicLayout/ClassicLayout';
import {EmptyState} from '../Layouts/EmptyState/EmptyState';
import {LayoutId, LayoutNames} from '../../constants';
import {ProductPageAppProps} from '../../types/app-types';
import {QuickViewLayout} from '../Layouts/QuickViewLayout/QuickViewLayout';
import {ResponsiveLayout} from '../Layouts/ResponsiveLayout/ResponsiveLayout';
import {SimpleLayout} from '../Layouts/SimpleLayout/SimpleLayout';
import {SleekLayout} from '../Layouts/SleekLayout/SleekLayout';
import {SpotlightLayout} from '../Layouts/SpotlightLayout/SpotlightLayout';
import {StunningLayout} from '../Layouts/StunningLayout/StunningLayout';
import {TPAComponentsProvider} from 'wix-ui-tpa/TPAComponentsConfig';
import {getProductPageSettingsFromProps} from '../../commons/styleParamsService';
import {withGlobalProps} from '../../providers/globalPropsProvider';
import '../../styles/app.global.scss';

const layouts = {
  [LayoutId.EmptyState]: EmptyState,
  [LayoutId.Classic]: ClassicLayout,
  [LayoutId.QuickView]: QuickViewLayout,
  [LayoutId.Responsive]: ResponsiveLayout,
  [LayoutId.Simple]: SimpleLayout,
  [LayoutId.Sleek]: SleekLayout,
  [LayoutId.Spotlight]: SpotlightLayout,
  [LayoutId.Stunning]: StunningLayout,
};

export const LayoutContext = React.createContext('');

@withGlobalProps
export class ProductPageApp extends React.Component<ProductPageAppProps> {
  public componentDidMount(): void {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
    this.safeRun(this.props.notifyProduct);
  }

  public componentDidUpdate(prevProps: Readonly<ProductPageAppProps>): void {
    if (
      this.props.globals.product &&
      (prevProps.globals.product.id !== this.props.globals.product.id ||
        prevProps.globals.isInteractive !== this.props.globals.isInteractive)
    ) {
      this.safeRun(this.props.notifyProduct);
    }
  }

  private readonly reportAppLoaded = () => {
    this.safeRun(this.props.globals.onAppLoaded);
  };

  private safeRun(fn) {
    if (this.props.globals.isInteractive && typeof fn === 'function') {
      fn();
    }
  }

  public render(): JSX.Element {
    const {product, isDesktop, layoutId, isMobile, isRTL, isQuickView, isEditorX} = this.props.globals;
    const fakeUITPAMobileMode = isQuickView; //todo(@nitzansi) make UI TPA accept isNativeSelect in dropdown by force
    const settings = getProductPageSettingsFromProps(this.props);
    const LayoutComp: any = layouts[layoutId];
    const layoutName = LayoutNames[layoutId];
    const layoutClass = `layout__${layoutName}`;
    const classNames = cx(s.ProductPageApp, layoutClass, {
      [s.mobile]: isMobile,
      isDesktop,
      isMobile,
      isQuickView,
      isRTL,
      responsive: isEditorX,
    });

    return (
      <TPAComponentsProvider value={{mobile: isMobile || fakeUITPAMobileMode, rtl: isRTL}}>
        <LayoutContext.Provider value={layoutName}>
          <div data-hook="product-page" className={classNames} data-layout-name={layoutName}>
            <LayoutComp
              data-hook="product-layout"
              product={product}
              settings={settings}
              globals={this.props.globals}
              {...this.props}
            />
          </div>
        </LayoutContext.Provider>
      </TPAComponentsProvider>
    );
  }
}
