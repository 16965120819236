import React from 'react';
import {ProductColors as OldProductColors} from './old/ProductColors/ProductColors';
import {NumberInputSpinner} from './NumberInputSpinner/NumberInputSpinner';
import {withGlobalProps, ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import s from './ProductOptions.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {TextOption} from './OptionInputText/TextOption';
import {OptionsDropdown as OldOptionsDropdown} from './old/OptionsDropdown/OptionsDropdown';
import {UserInputType} from '../../constants';
import {selectProductOptionItems} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {isFunction, isArray} from 'util';
import {ProductType} from '@wix/wixstores-client-core/dist/src/types';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IProductOption} from '@wix/wixstores-graphql-schema';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/es/src';
import {SubscriptionPlans} from './SubscriptionPlans/SubscriptionPlans';
import {IProductOptionSelectionItem} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {IProductDTO} from '../../types/app-types';
import {ProductColors} from './ProductColors/ProductColors';
import {OptionsDropdown} from './OptionsDropdown/OptionsDropdown';
/* eslint-disable react/jsx-no-useless-fragment */

export interface ProductOptionsProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  shouldShowQuantity: boolean;
}

interface ProductOptionsState {
  addedToWishlist: boolean;
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductOptions extends React.Component<ProductOptionsProps, ProductOptionsState> {
  public state = {
    addedToWishlist: false,
  };

  /**
   * @deprecated
   * remove when specs.stores.OptionsFromVariantCalculatorOnProductPage is merged
   */
  public handleUserInput = (inputType: UserInputType, index: number) => (
    data: IProductOptionSelectionItem[] | string
  ): void => {
    const {handleUserInput, validate} = this.props.globals;

    const input = isArray(data) ? data[0] : data;

    /* istanbul ignore else: todo: test */
    if (isFunction(handleUserInput)) {
      handleUserInput(inputType, input, index);
    }

    validate();
  };

  /**
   * @deprecated in favor of ProductOptions.renderOptions
   * @see https://jira.wixpress.com/browse/EE-26700
   */
  private options() {
    const {product} = this.props.globals;

    return selectProductOptionItems(product);
  }

  private renderOptions(): JSX.Element {
    const {
      product,
      experiments: {optionsFromVariantCalculator},
    } = this.props.globals;

    if (!optionsFromVariantCalculator) {
      return this.oldRenderOptions();
    }

    return <>{product.options.map(this.renderOption)}</>;
  }

  private readonly renderOption = (option: IProductDTO['options'][number], index: number): JSX.Element => {
    if (option.optionType === ProductOptionType.COLOR) {
      return (
        <Cell className={s.colors} key={`product-options-${index}`}>
          <ProductColors optionIndex={index} />
        </Cell>
      );
    }

    return (
      <Cell key={`product-options-${index}`}>
        <OptionsDropdown optionIndex={index} />
      </Cell>
    );
  };

  /**
   * @deprecated in favor of ProductOptions.renderOptions
   */
  private oldRenderOptions(): JSX.Element {
    const {
      globals: {userInputs},
    } = this.props;
    return (
      <>
        {this.options().map(
          //eslint-disable-next-line array-callback-return
          (optionItem, i): JSX.Element => {
            switch (optionItem.optionType) {
              case ProductOptionType.COLOR:
                return (
                  <Cell className={s.colors} key={`product-options-${i}`}>
                    <OldProductColors
                      errorIndex={i}
                      options={optionItem as IProductOption}
                      onSelect={this.handleUserInput(UserInputType.Selection, i)}
                      selected={userInputs[UserInputType.Selection][i] ? [userInputs[UserInputType.Selection][i]] : []}
                    />
                  </Cell>
                );
              case ProductOptionType.DROP_DOWN:
                return (
                  <Cell key={`product-options-${i}`}>
                    <OldOptionsDropdown
                      errorIndex={i}
                      dropdown={optionItem}
                      onSelect={this.handleUserInput(UserInputType.Selection, i)}
                      selected={userInputs[UserInputType.Selection][i]}
                    />
                  </Cell>
                );
            }
          }
        )}
      </>
    );
  }

  private renderQuantity() {
    const {
      t,
      globals: {product, quantityRange, userInputs},
    } = this.props;

    const shouldShowQuantity = this.props.shouldShowQuantity && product.productType === ProductType.PHYSICAL;
    const quantity = userInputs[UserInputType.Quantity][0];

    if (!shouldShowQuantity) {
      return null;
    }

    return (
      <Cell className={s.quantity}>
        <NumberInputSpinner
          max={quantityRange.max}
          value={quantity}
          title={t('QUANTITY_LABEL')}
          onChange={this.handleUserInput(UserInputType.Quantity, 0)}
        />
      </Cell>
    );
  }

  private renderCustomText() {
    const {
      globals: {
        product: {customTextFields},
      },
    } = this.props;

    return customTextFields.map((customTextField, index) => {
      return (
        <Cell key={index}>
          <TextOption
            errorIndex={index}
            title={customTextField.title}
            isRequired={customTextField.isMandatory}
            value={''}
            maxLength={customTextField.inputLimit}
            handleOnChange={this.handleUserInput(UserInputType.Text, index)}
          />
        </Cell>
      );
    });
  }

  private renderSubscriptionPlans(): JSX.Element {
    return (
      <Cell>
        <SubscriptionPlans />
      </Cell>
    );
  }

  public render(): JSX.Element {
    const {
      globals: {
        product,
        product: {customTextFields},
        shouldShowSubscriptionPlans,
        shouldShorUserInputs,
      },
    } = this.props;

    return (
      <div
        data-hook="product-options"
        className={s.productOptionsContainer}
        key={`product-option-${product.id}-${product.currency}`}>
        {shouldShorUserInputs && (
          <div data-hook="product-options-inputs">
            {this.renderOptions()}
            {customTextFields && this.renderCustomText()}
            {this.renderQuantity()}
          </div>
        )}
        {shouldShowSubscriptionPlans && this.renderSubscriptionPlans()}
      </div>
    );
  }
}
