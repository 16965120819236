import React from 'react';
import s from './InfoSectionTitle.scss';
import {InfoSectionLayoutId} from '../../../constants';
import classNames from 'classnames';
import {Text} from 'wix-ui-tpa/Text';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {classes as infoSectionTitleStylable} from './InfoSectionTitle.st.css';

export enum DataHook {
  Title = 'info-section-title',
}

export interface InfoSectionTitleProps {
  title: string;
  theme: InfoSectionLayoutId;
}

@withGlobalProps
export class InfoSectionTitle extends React.Component<InfoSectionTitleProps & ProvidedGlobalProps> {
  public render(): JSX.Element {
    const {
      theme,
      title,
      globals: {experiments, htmlTags},
    } = this.props;

    const titleClassName = classNames(
      s.general,
      {[s.collapse]: theme === InfoSectionLayoutId.Collapse},
      {[s.stacked]: theme === InfoSectionLayoutId.Stacked},
      {[s.tabs]: theme === InfoSectionLayoutId.Tabs},
      {[s.columns]: theme === InfoSectionLayoutId.Columns},
      {[infoSectionTitleStylable.root]: experiments.isEditableHeadingTags}
    );

    return experiments.isEditableHeadingTags ? (
      <Text tagName={htmlTags.infoSectionTitleHtmlTag} className={titleClassName} data-hook={DataHook.Title}>
        {title}
      </Text>
    ) : (
      <h3 className={titleClassName} data-hook={DataHook.Title}>
        {title}
      </h3>
    );
  }
}
